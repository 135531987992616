<template>
  <div class="database-overview">
    <div class="section">
      <div class="flex-row">
        <div class="flex-column">
          <p class="supplemental">Individual ancestors</p>
          <h4 class="counter">150+ million</h4>
        </div>
        <div class="flex-column">
          <p class="supplemental">Images digitized</p>
          <h4 class="counter">14+ million</h4>
        </div>
      </div>
      <img
        :data-src="$getAsset('/assets/services/world-map-pin-no-white.png')"
        class="lazyload map-image"
        width="800"
        height="600"
      />
    </div>

    <div class="section categories-section">
      <div class="heading-4">Available Record Categories</div>
      <div class="categories">
        <record-category-block :item="item" v-for="item in items" :key="item.id"></record-category-block>
      </div>
    </div>

    <div class="additional_panel">
      <h5 class="panel-title">Work with us to preserve the Chinese historical records of your organization.</h5>
      <mcr-button-router-link
        :to="{name: 'institutions-indexing'}"
        label="Preserve with Us"
        class="fat"
      ></mcr-button-router-link>
    </div>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import RecordCategoryBlock from '@common/elements/layouts/RecordCategoryBlock';
import orderBy from 'lodash/orderBy';
import {mapGetters} from 'vuex';

export default {
  created() {
    this.init();
  },
  computed: {
    ...mapGetters(['searchCategoriesListState']),
    items() {
      return orderBy(this.searchCategoriesListState, ['is_highlighted', 'priority'], ['desc', 'asc']);
    },
  },
  methods: {
    init() {
      if (!this.searchCategoriesListState.length) {
        this.$store.dispatch('fetchSearchCategoriesListAction');
      }
    },
  },
  name: 'DatabaseOverview',
  components: {McrButtonRouterLink, RecordCategoryBlock},
};
</script>

<style lang="scss" scoped>
.database-overview {
  background-color: #f3eee8;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: $desktop-content-padding 0;

  .section {
    max-width: $content-max-width;
    padding: $desktop-content-padding;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flex-row {
    display: flex;
    width: 100%;
    justify-content: space-around;
    max-width: $reading-max-width;
    .flex-column {
      align-items: center;
      p {
        text-align: center;
      }
    }
    .counter {
      margin: 0;
    }
  }

  .categories-section {
    margin-top: 4px;
    .categories {
      margin: 24px auto 0;
      max-width: $reading-max-width;
      display: grid;
      grid-gap: 32px;
      grid-template-columns: repeat(2, 1fr);

      @media only screen and (max-width: $breakpoint-mobile) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  .map-image {
    margin: 40px 0 0;
  }

  .additional_panel {
    max-width: $reading-max-width;
    margin: 0 auto;
    padding: 0 25px;
    border-top: 1px solid $divider-line-color;
    display: flex;
    flex-direction: column;
    align-items: center;

    .panel-title {
      padding: 40px 0 20px;
      text-align: center;
    }
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    .flex-row {
      .counter {
        font-size: 48px;
        line-height: 48px;
      }
    }
  }
  @media only screen and (max-width: $breakpoint-phablet) {
    padding: $mobile-se-content-padding 0;

    .section {
      padding: $mobile-se-content-padding 0;
    }

    .flex-row {
      flex-direction: column;
      .flex-column:not(:last-child) {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
    .flex-row.records-links {
      .flex-column {
        width: 100%;
      }
    }
  }
}
</style>
